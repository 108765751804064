<script>
import {debounce, get} from 'lodash'
import moment from 'moment'
import axios from 'axios'
import config from '@/config.js'
import CouponItem from '@/components/Form/components/CouponItem';
import Loader from '@/components/Loader';

export default {
  props: [   
    "value",
    "data",       
    "payload",
    "theme",
    "discountLists",
    "cartSummary",
    "totalQuantity",
    "totalPrice",
    "price"      
  ], 
  components:{
    Loader,
    CouponItem
  },
  data() {
    return {
      modal: false,   
      searchCoupon:'',
      couponPaging: {
        size: 5,
        page: 1      
      },
      couponTotal:0,
      couponCount:0,
      coupons:{
        products:{
          loading:false,
          list:[],
          page: 1,
          size:5,
          isExpanded:false,
          total:0
        },
        orders:{
          loading:false,
          list:[],
          page: 1,
          size:5,
          isExpanded:false,
          total:0
        },
        shippings:{
          loading:false,
          list:[],
          page: 1,
          size:5,
          isExpanded:false,
          total:0
        }       
      },
      isExpanded: false,
      moment      
    }
  },
  mounted(){
    const self=this;
    this.handleAutofill = debounce(async function (type='') {
      if(type!=''){
        this.coupons[type].loading=true;
      }
      const search=self.searchCoupon.length > 3 ? self.searchCoupon :'';   
      const page= type=='' ? this.couponPaging.page : this.coupons[type].page
      const size= type=='' ? this.couponPaging.size : this.coupons[type].size    
      const response = await axios.get(config.api +"product/discountList/"+this.data._id+"?offset="+new Date().getTimezoneOffset()+"&user_id="+this.data.user_id+"&search="+search+"&size="+size+"&page="+page+"&type="+type+"&getAllData=true"+"&product_type="+this.data.productType);       
      if (response.data.success && response.data.data && Object.keys(response.data.data).length) {   
        if (this.payload.automaticDiscount && this.payload.automaticDiscount.code) {
          switch (this.payload.automaticDiscount.discount_type) {
            case 'moneyOffProduct':
              response.data.data.products.unshift({ title: this.payload.automaticDiscount.code, ...this.payload.automaticDiscount });
              response.data.count.products += 1;
              break;
            case 'moneyOffShipping':
              response.data.data.shippings.unshift({ title: this.payload.automaticDiscount.code, ...this.payload.automaticDiscount });
              response.data.count.shippings += 1;
              break;
            case 'moneyOffOrder':
              response.data.data.orders.unshift({ title: this.payload.automaticDiscount.code, ...this.payload.automaticDiscount });
              response.data.count.orders += 1;
              break;
            case '':
              break;
          }
        }
        
        if(type==''){
          const { products, orders, shippings } = response.data.data;    
          if(page==1){
            this.coupons.products.list = [...this.filterDiscounts(products)];
            this.coupons.products.count = response.data.count.products;
            this.coupons.orders.list = [...this.filterDiscounts(orders)]; 
            this.coupons.orders.count = response.data.count.orders;
            this.coupons.shippings.list = [...this.filterDiscounts(shippings)]; 
            this.coupons.shippings.count = response.data.count.shippings;  
          }else{
            this.coupons.products.list = [...this.coupons.products.list,...this.filterDiscounts(products)];
            this.coupons.products.count = response.data.count.products;
            this.coupons.orders.list = [...this.coupons.orders.list,...this.filterDiscounts(orders)]; 
            this.coupons.orders.count = response.data.count.orders;
            this.coupons.shippings.list = [...this.coupons.shippings.list,...this.filterDiscounts(shippings)]; 
            this.coupons.shippings.count = response.data.count.shippings;  

          }            
         
          this.coupons.products.list=this.uniqueById(this.coupons.products.list, 'title')      
          this.coupons.orders.list=this.uniqueById(this.coupons.orders.list, 'title')      
          this.coupons.shippings.list=this.uniqueById(this.coupons.shippings.list, 'title')      
          

          this.updateDiscountLists(this.coupons.products.list,false) 
          this.updateDiscountLists(this.coupons.orders.list,false) 
          this.updateDiscountLists(this.coupons.shippings.list,false) 
         
        }else{ 
          if(page==1){
            this.coupons[type].count = response.data.count[type];
            this.coupons[type].list =[...this.filterDiscounts(response.data.data[type])];   
            this.coupons[type].list=this.uniqueById(this.coupons[type].list, 'title')     
            this.updateDiscountLists(this.coupons[type].list,false)           
            this.coupons[type].loading=false;
          }else{
            this.coupons[type].count = response.data.count[type];
            this.coupons[type].list =[... this.coupons[type].list,...this.filterDiscounts(response.data.data[type])];   
            this.coupons[type].list=this.uniqueById(this.coupons[type].list, 'title')     
            this.updateDiscountLists(this.coupons[type].list,false)           
            this.coupons[type].loading=false;
          }      
          
        }  
        
      }else if(page==1){
        if(type==''){
          this.coupons.products.list =[];
          this.coupons.orders.list =[];
          this.coupons.shippings.list = [];
        }else{
          this.coupons[type].list = [];  
        }       
        this.couponTotal=0
        this.couponCount=0     
       }        
       
       if (this.data.productType === 'digital') {
        this.coupons.shippings.list = [];
        this.updateDiscountLists(this.coupons.shippings.list,false) 
       }
    }, 500);
   
    this.handleAutofill('')

    //this.coupons=this.discountLists;
  },
  computed: {
    assets() {
      return {
        icon: {
          tuktuk: require('@/assets/icons/discount-circle-red.svg'),
          classic: require('@/assets/icons/discount-circle.svg'),
        },
        productCoupon: {
          tuktuk: require('@/assets/images/illustrations/icon-illustration-coupon-item.svg'),
          classic: require('@/assets/images/illustrations/icon-illustration-coupon-item.svg'),
        },
        orderCoupon: {
          tuktuk: require('@/assets/images/illustrations/icon-illustration-coupon-order.svg'),
          classic: require('@/assets/images/illustrations/icon-illustration-coupon-order.svg'),
        },
        shippingCoupon: {
          tuktuk: require('@/assets/images/illustrations/icon-illustration-coupon-shipping.svg'),
          classic: require('@/assets/images/illustrations/icon-illustration-coupon-shipping.svg'),
        },
        empty: {
          tuktuk: require('@/assets/images/empty-state/coupons-empty-red.svg'),
          classic: require('@/assets/images/empty-state/coupons-empty-green.svg'),
        }
      }
    },    
    width() {
      return this.theme === 'classic' ? '578px' : '530px'
    },
    couponProducts() {
      if (!this.coupons.products.isExpanded) return this.coupons.products.list.slice(0, 3)
      return this.coupons.products.list
    },
    couponOrders() {
      if (!this.coupons.orders.isExpanded) return this.coupons.orders.list.slice(0, 3)
      return this.coupons.orders.list
    },
    couponShippings() {
      if (!this.coupons.shippings.isExpanded) return this.coupons.shippings.list.slice(0, 3)
      return this.coupons.shippings.list
    },
    loaderColor() {
      if (this.theme === 'tuktuk') return '#F75656';
      return '#3BBA6A'
    }
  },
  methods:{    
    uniqueById(arr, key){
      const seen = new Set();
      return arr.filter(item => {
        const value = item[key];
        if (seen.has(value)) {
          return false;
        } else {
          seen.add(value);
          return true;
        }
      });
    },
    createObserver(type) {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      };

      if(type=='products'){       
        const observer = new IntersectionObserver((entries) => {               
          if (entries[0].isIntersecting && !this.coupons[type].loading) {            
            if(this.coupons[type].count!=this.coupons[type].list.length){
              this.coupons[type].page= this.coupons[type].page+1
              this.handleAutofill(type)               
            }
          }
        }, options);
        observer.observe(this.$refs.productObserver);
      }    

      if(type=='orders'){
        const observer = new IntersectionObserver((entries) => {        
          if (entries[0].isIntersecting && !this.coupons[type].loading) {
            if(this.coupons[type].count!=this.coupons[type].list.length){
              this.coupons[type].page= this.coupons[type].page+1
              this.handleAutofill(type)       
            }
          }
        }, options);
        observer.observe(this.$refs.orderObserver);
      }    

      if(type=='shipping'){
        const observer = new IntersectionObserver((entries) => {        
          if (entries[0].isIntersecting && !this.coupons[type].loading) {
            if(this.coupons[type].count!=this.coupons[type].list.length){
              this.coupons[type].page= this.coupons[type].page+1
              this.handleAutofill(type)       
            }
          }
        }, options);
        observer.observe(this.$refs.shippingObserver);
      }    

    },  
    changeExpanded(type){
      const coupon = this.coupons[type];

      if (!coupon) return; // Ensure the coupon exists

      // Toggle the expanded state for the coupon
      coupon.isExpanded = !coupon.isExpanded;

      // Only scroll when the coupon is collapsing (i.e., isExpanded is false)
      if (!coupon.isExpanded) {
        const element = this.$refs[`main-coupon-${type}`];
        
        if (element) {
          // Use `scrollIntoView` with proper options
          element.scrollIntoView({
            behavior: 'smooth', // Smooth scroll
            block: 'start',     // Align to the top of the viewport
            inline: 'nearest',  // Align to the nearest edge
          });
        } else {
          console.warn(`Element with ref "main-coupon-${type}" not found.`);
        }
      }
    },   
    // Define a function to filter discounts based on `applies_to` conditions
    filterDiscounts(discountArray) {
      return discountArray.filter(d =>
        d.discount_value.applies_to.type === 'specific_products' &&
        d.discount_value.applies_to.products.length > 0
            ? d.discount_value.applies_to.products.indexOf(this.data._id) > -1
            : true
      );
    },
    checkdisabled(discount) {      
      const exclusiveDiscount = this.payload.discounts.find(discount => discount.is_exclusive);
      if (exclusiveDiscount && discount.code !== exclusiveDiscount.code) {
        return false;
      }

      if (
        get(this.payload, "automaticDiscount.code.length") &&
        get(this.payload, "automaticDiscount.code") === discount.code
      ) {
        return false;
      }
     
      let totalQuantity=this.totalQuantity;
      let price =  this.price;  
      if(this.cartSummary && this.cartSummary.length > 0){
          totalQuantity=0;
          price = 0;
          this.cartSummary.map(async (e) => {      
            totalQuantity = totalQuantity + e.qty;     
            let subPrice = (e.price ? e.price : e.priceOriginal) * e.qty;
              if (e.whosaleData) {
                e.whosaleData.map((one) => {
                  if (one.count && parseInt(one.count) <= parseInt(e.qty)) {
                    subPrice = one.price * parseInt(e.qty);
                  }               
                });
              }             
              price = price + subPrice;     
           
        });
      }      

      if (totalQuantity < 1) {
        return false;
      }
        
      let discount_amount=0;        
      if (discount.product_type !== this.data.productType) {
        return false;
      }

      if(discount.minimum_requirements.type=='minimum_purchase'){         
          if(Number(discount.minimum_requirements.value)>this.totalPrice){
            return false;
          }        
        }else if(discount.minimum_requirements.type=='minimum_quantity'){       
          if(Number(discount.minimum_requirements.value)>totalQuantity){
            return false;
          }        
        } 
       
        if (discount.payment_type && discount.payment_type !== 'both') {
          const isCOD = this.payload.payment === 'COD';
          if (
            (discount.payment_type === 'cod' && !isCOD) ||
            (discount.payment_type === 'non_cod' && isCOD)
          ) {
            return false;
          }
        }

        if(discount.discount_type=="moneyOffProduct"){
            if(discount.discount_value.type=="fixed"){                   
              discount_amount=(Number(discount.discount_value.value));           
            }else{
              discount_amount =(price *Number(discount.discount_value.value)) / 100;                                        
                            
            }       
            
        }else if(discount.discount_type=="moneyOffOrder"){
          if(discount.discount_value.type=="fixed"){                   
            discount_amount=Number(discount.discount_value.value);         
          }else{
            discount_amount =(this.totalPrice *Number(discount.discount_value.value)) / 100;                             
                    
          }  
        }else if(discount.discount_type=="moneyOffShipping"){
          if (this.payload.deliveryPrice) {
            if (discount.discount_value.applies_to.type === 'spcific_products') {
              if (!discount.discount_value.applies_to.products.includes(this.data.product_id)) {
                return false;
              }
            }
            if (discount.discount_value.applies_to.shipping_type=='all' || discount.discount_value.applies_to.shipping_type==this.payload.deliveryCourier) {
              if (discount.discount_value.type=="fixed"){                   
                discount_amount=Number(discount.discount_value.value);         
              } else{
                discount_amount =(this.payload.deliveryPrice *Number(discount.discount_value.value)) / 100;    
              }

              return discount_amount > 0    
            } else {
              return false;
            }
          }
         
          return true;
        }       
        return discount_amount > 0        
    },
    clearDiscountCode(code){
      this.$emit('clearDiscountCode', code)
      this.modal=false
    },   
    updateDiscountLists(discounts,replace){     
      this.$emit('updateDiscountLists',discounts,replace)
    },
    applyDiscount(discount){   
      const isApply=this.checkdisabled(discount)   
      if(isApply){      
        this.$emit('selectDiscount',discount)
        this.modal=false
      }
    }
  },
  watch: {
    'couponPaging.page': {
        async handler() {              
            this.handleAutofill()              
        }
    },
    value(open) {
      if(open){
        //this.coupons=[...this.discountLists]
        this.searchCoupon=''
      }
      this.modal = open
    },
    modal(open) {
      this.handleAutofill();
      if (!open) this.$emit('input', false)
    },
  'searchCoupon': {
        async handler(val) {
            if (val.length > 3) {
              this.couponPaging.page=1
              this.handleAutofill()
            }else{
              this.handleAutofill()
            }
        }
    },    
    payload() {
      console.log(this.payload)
    }
  }
}
</script>

<template>
  <vs-dialog  :prevent-close="true" v-model="modal" :data-theme="theme" class="pp-modal-vuesax variant-modal pp-modal-choose-variant--mobile" :width="width">
    <div class="pp-modal-vuesax__header">
      <img :src="assets.icon[theme || 'classic']" alt="O">
      <h3 class="m-0">{{$t('coupon.CouponList')}}</h3>
    </div>
    <hr class="m-0" />    
    <div class="pp-modal-vuesax__main">
      <input v-model="searchCoupon" class='search-white border w-100' :placeholder="$t('coupon.Lookforcoupons')" />       
      <div v-if="!(coupons.products.list.length ==0 && coupons.orders.list.length ==0 && coupons.shippings.list.length ==0)">
        <template v-if="coupons && coupons.products.list && coupons.products.list.length>0">
          <div>
            <p class="fs-16 fw-600" style="margin-bottom: 8px;" ref="main-coupon-products">Diskon Produk</p>   
            <div class="coupon-lists">
              <CouponItem
                v-for="discount,index in couponProducts"
                :key="`coupon-${index}`"
                :discount="discount"
                :IsDisabled="checkdisabled(discount)"
                :theme="theme"
                :payload="payload"
                :totalQuantity="totalQuantity"
                :price="price"
                :totalPrice="totalPrice"
                @clearDiscountCode="clearDiscountCode"
                @applyDiscount="applyDiscount"
              />       
              <div ref="productObserver" class="observer"></div>            
              <loader v-if="coupons.products.loading" :color="loaderColor" variant="dots-1" />
            </div>       
            <div class="coupon-show-more" :data-expanded="coupons.products.isExpanded" @click="changeExpanded('products')" v-if="coupons.products.count > 3">
              <button>
                {{ coupons.products.isExpanded ? $t('common.hide') : $t('common.ShowAll') }}
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.3721 7.91138C5.60524 7.65682 6.00058 7.63946 6.25514 7.87259L10.0003 11.3026L13.7441 7.87266C13.9987 7.63949 14.394 7.65679 14.6272 7.9113C14.8604 8.16582 14.8431 8.56117 14.5885 8.79434L10.4226 12.611C10.1837 12.8299 9.81715 12.8299 9.57823 12.6111L5.41089 8.79441C5.15634 8.56128 5.13897 8.16593 5.3721 7.91138Z" fill="#14AD4D"/>
                </svg>
              </button>
            </div>
          </div>  
        </template>   
        <template v-if="coupons && coupons.orders.list && coupons.orders.list.length>0">   
          <div>
            <p class="fs-16 fw-600" style="margin-bottom: 8px;" ref="main-coupon-orders">Diskon Order</p>
            <div class="coupon-lists">
              <CouponItem
                v-for="discount,index in couponOrders"
                :key="`coupon-${index}`"
                :discount="discount"
                :IsDisabled="checkdisabled(discount)"
                :theme="theme"
                :payload="payload"
                :totalQuantity="totalQuantity"
                :price="price"
                :totalPrice="totalPrice"
                @clearDiscountCode="clearDiscountCode"
                @applyDiscount="applyDiscount"
              />       
              <loader v-if="coupons.orders.loading" :color="loaderColor" variant="dots-1" />
              <div ref="orderObserver" class="observer"></div>                           
            </div>
            <div class="coupon-show-more" :data-expanded="coupons.orders.isExpanded" @click="changeExpanded('orders')" v-if="coupons.orders.count > 3">
              <button>
                {{ coupons.orders.isExpanded ? $t('common.hide') : $t('common.ShowAll') }}
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.3721 7.91138C5.60524 7.65682 6.00058 7.63946 6.25514 7.87259L10.0003 11.3026L13.7441 7.87266C13.9987 7.63949 14.394 7.65679 14.6272 7.9113C14.8604 8.16582 14.8431 8.56117 14.5885 8.79434L10.4226 12.611C10.1837 12.8299 9.81715 12.8299 9.57823 12.6111L5.41089 8.79441C5.15634 8.56128 5.13897 8.16593 5.3721 7.91138Z" fill="#14AD4D"/>
                </svg>
              </button>
            </div>
          </div>  
        </template>       
        <template v-if="coupons && coupons.shippings.list && coupons.shippings.list.length>0">              
          <div>
            <p class="fs-16 fw-600" style="margin-bottom: 8px;" ref="main-coupon-shippings">Diskon Pengiriman</p>
            <div class="coupon-lists">
              <CouponItem
                v-for="discount,index in couponShippings"
                :key="`coupon-${index}`"
                :discount="discount"
                :IsDisabled="checkdisabled(discount)"
                :theme="theme"
                :payload="payload"
                :totalQuantity="totalQuantity"
                :price="price"
                :totalPrice="totalPrice"
                @clearDiscountCode="clearDiscountCode"
                @applyDiscount="applyDiscount"
              />       
              <loader v-if="coupons.shippings.loading" :color="loaderColor" variant="dots-1" />
              <div ref="shippingObserver" class="observer"></div>         
            </div>
            <div class="coupon-show-more" :data-expanded=" coupons.shippings.isExpanded"  @click="changeExpanded('shippings')" v-if="coupons.shippings.count > 3">
              <button>
                {{  coupons.shippings.isExpanded ? $t('common.hide') : $t('common.ShowAll') }}
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.3721 7.91138C5.60524 7.65682 6.00058 7.63946 6.25514 7.87259L10.0003 11.3026L13.7441 7.87266C13.9987 7.63949 14.394 7.65679 14.6272 7.9113C14.8604 8.16582 14.8431 8.56117 14.5885 8.79434L10.4226 12.611C10.1837 12.8299 9.81715 12.8299 9.57823 12.6111L5.41089 8.79441C5.15634 8.56128 5.13897 8.16593 5.3721 7.91138Z" fill="#14AD4D"/>
                </svg>
              </button>
            </div>
          </div>        
       </template>       
      </div>
      <div v-else class="flex flex-column items-center justify-center" style="padding: 80px">
        <img :src="assets.empty[theme || 'classic']" alt="Empty" style="margin-bottom: 8px;">
        <p class="fs-14 fw-500 color-mute m-0">{{$t('coupon.Couponnotfound')}}</p>
      </div>
    </div>
  </vs-dialog>
</template>

 

<style lang="scss" scoped>
[data-theme="tuktuk"] {
  &::v-deep {
    .loader {
      margin: 0 auto;
    }
  }

  .coupon-show-more {
    display: flex;
    justify-content: center;
    align-items: center;

    &[data-expanded=true] {
      button svg {
        transform: rotate(180deg);
      }
    }

    button {
      height: unset !important;
      padding: 0 !important;
      margin-top: 8px;
      border-radius: 0 !important;
      background: none !important;
      display: flex;
      align-items: center;
      gap: 4px;
      color: #F75656;
      font-size: 14px;
      font-weight: 500;
    }
  }

  h3 {
    font-family: TikTokDisplayMedium !important;
    font-weight: 600;
  }

  .coupon__item {
    background: #FEEBEB;
    border-radius: 16px;
    row-gap: 0 !important;

    @media screen and (max-width: 500px) {
      grid-template-columns: auto 1fr !important;
      grid-template-rows: auto auto;
      row-gap: 16px !important;
      padding: 16px !important;
    }

    &::before {
      content: '';
      position: absolute;
      top: -12px;
      left: 56px;
      display: block;
      height: 24px;
      width: 24px;
      border-radius: 48px;
      background: #FFF;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -12px;
      left: 56px;
      display: block;
      height: 24px;
      width: 24px;
      border-radius: 48px;
      background: #FFF;
    }

    &>div:first-child {
      grid-row: 1 / 3;
    }

    img {
      margin-right: 24px;
      height: 40px;
      width: 40px;
    }
    
    &__shipping__products {
      grid-column: 2 / 4;

      hr {
        border-color: #F75656;
      }
      span {
        color: #F53535 !important;
      }
      path {
        fill: #F53535 !important;
      }
    }
    
    &__divider {
      position: absolute;
      top: 50%;
      left: 56px;
      transform: translateY(-50%);
      height: 140px;
      margin: 0 10px;
      border-left: 4px dashed #FFF;
    }

    &__name {
      font-family: TikTokDisplayBold;
      margin-bottom: 4px;
      color: #203551;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;

      @media screen and (max-width: 500px) {
        margin-bottom: 0px;
      }
    }
    &__code {
      margin-bottom: 4px;
      color: #F53535;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;

      @media screen and (max-width: 500px) {
        font-size: 12px;
        margin-bottom: 0px;
        line-height: 18px;
      }
    }
    &__description {
      margin-bottom: 0px;
      color: #15171C;
      opacity: 0.4;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      @media screen and (max-width: 500px) {
        font-size: 12px;
        margin-bottom: 0px;
        line-height: 18px;
      }
    }
  }
  .pp-button {
    &--fill {
      background: #F75656;

      @media screen and (max-width: 500px) {
        width: 100% !important;
        grid-column: 2 / 3;
      }
    }
    &--border {
      color: #F75656;
      background: #fff;
      border: 1px solid #F75656 !important;

      @media screen and (max-width: 500px) {
        width: 100% !important;
        grid-column: 2 / 3;
      }
    }
  }
  &::v-deep {
    .vs-pagination {
      gap: 8px !important;

      &-content {
        gap: 8px !important;
      }

      &__button {
        color: #EB435B;
        background-color: #FFF;
        border-radius: 40px;
        border: 1px solid #E0E9F4;
        border-radius: 40px !important;
      }

      &__active {
        color: #FFF;
        background-color: #EB435B !important;
        box-shadow: 0px 10px 20px 0px #00136A40;
        border-radius: 40px !important;
      }

      &__arrow {
        background-color: #FFF;
        border: 1px solid #EB435B !important;
        border-radius: 40px !important;
        
        .vs-icon-arrow::before,
        .vs-icon-arrow::after {
          background-color: #EB435B !important;
        }

        &:disabled {
          opacity: 1 !important;
          border: 1px solid #E0E9F4 !important;
          
          .vs-icon-arrow::before,
          .vs-icon-arrow::after {
            background-color: #203551 !important;
          }
        }
      }
    }
  }
}
[data-theme="classic"] {
  &::v-deep {
    .loader {
      margin: 0 auto;
    }
  }

  .coupon-show-more {
    display: flex;
    justify-content: center;
    align-items: center;

    &[data-expanded=true] {
      button svg {
        transform: rotate(180deg);
      }
    }

    button {
      height: unset !important;
      padding: 0 !important;
      margin-top: 8px;
      border-radius: 0 !important;
      background: none !important;
      display: flex;
      align-items: center;
      gap: 4px;
      color: #14AD4D;
      font-size: 14px;
      font-weight: 700;
    };
  }

  .coupon__item {
    background: #FFF;
    border-radius: 8px;
    border: 1px solid #E0E9F4;

    @media screen and (max-width: 500px) {
      gap: 8px !important;
      grid-template-columns: auto 1fr !important;
      grid-template-rows: auto auto;
      padding: 12px !important;
    }

    img {
      height: 64px;
      width: 64px;
    }
    
    &__divider {
      display: none;
    }
    
    &__shipping__products {
      grid-column: 1 / 4;

      hr {
        border-color: #BFCEE0;
      }
      span {
        color: #9D6508 !important;
      }
      fill {
        fill: #9D6508 !important;
      }
    }

    &__name {
      margin-bottom: 4px;
      color: #203551;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;

      @media screen and (max-width: 500px) {
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }
    &__code {
      margin-bottom: 4px;
      color: #14AD4D;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;

      @media screen and (max-width: 500px) {
        margin-bottom: 0px;
        font-size: 12px;
        line-height: 18px;
      }
    }
    &__description {
      margin-bottom: 0px;
      color: #15171C;
      opacity: 0.4;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      @media screen and (max-width: 500px) {
        margin-bottom: 0px;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .pp-button {
    &--fill {
      background: #3BBA6A;
      box-shadow: 0px 1px 2px 0px #0000001F;

      @media screen and (max-width: 500px) {
        width: 100% !important;
        grid-column: 1 / 3;
      }
    }
    &--border {
      color: #14AD4D;
      background: #fff;
      border: 1px solid #14AD4D !important;

      @media screen and (max-width: 500px) {
        width: 100% !important;
        grid-column: 1 / 3;
      }
    }
  }
  &::v-deep {
    .vs-pagination {
      gap: 8px !important;

      &-content {
        gap: 8px !important;
      }

      &__button {
        color: #14AD4D;
        background-color: #FFF;
        border-radius: 40px;
        border: 1px solid #E0E9F4;
        border-radius: 40px !important;
      }

      &__active {
        color: #FFF;
        background-color: #14AD4D !important;
        box-shadow: 0px 10px 20px 0px #00136A40;
        border-radius: 40px !important;
      }

      &__arrow {
        background-color: #FFF;
        border: 1px solid #14AD4D !important;
        border-radius: 40px !important;
        
        .vs-icon-arrow::before,
        .vs-icon-arrow::after {
          background-color: #14AD4D !important;
        }

        &:disabled {
          opacity: 1 !important;
          border: 1px solid #E0E9F4 !important;
          
          .vs-icon-arrow::before,
          .vs-icon-arrow::after {
            background-color: #203551 !important;
          }
        }
      }
    }
  }
}

.pp-modal-vuesax {
  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 24px;

    h3 {
      font-size: 24px;
    }

    @media screen and (max-width: 500px) {
      padding: 16px 20px;

      h3 {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  &__main {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-height: 570px;
    max-height: 570px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 12px;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background: #BFCEE0;
      border: 3px solid transparent;
      border-radius: 8px;
      background-clip: padding-box;  
    }

    @media screen and (max-width: 500px) {
      padding: 16px;
      gap: 16px;
    }

    .coupon-lists {
      // max-height: 664px;
      // overflow: auto;

      &::-webkit-scrollbar {
        width: 12px;
        cursor: pointer;
      }
      &::-webkit-scrollbar-track {
        border-radius: 20px;
      }
      &::-webkit-scrollbar-thumb {
        background: #BFCEE0;
        border: 3px solid transparent;
        border-radius: 8px;
        background-clip: padding-box;  
      }
    }

    .coupon__item {
      position: relative;
      flex-shrink: 0;
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 16px;
      padding: 9px 16px;
      letter-spacing: -0.2px;
      overflow: hidden;

      &__shipping {
        grid-template-rows: auto auto;

        svg {
          flex-shrink: 0;
        }
      }

      &__valid {
        margin-bottom: 0px;
        opacity: 0.4;
        color: #15171C;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .pp-button {
      &--fill {
        align-self: center;
        width: 114px;
        height: 32px !important;
        flex-shrink: 0;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        letter-spacing: -0.2px;
      }
      &--border {
        align-self: center;
        width: 114px;
        height: 32px !important;
        flex-shrink: 0;
        background: #FFF;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        letter-spacing: -0.2px;
        box-shadow: 0px 1px 2px 0px #0000001F;
      }
    }
  }

  .search-white {
    font-size: 14px;
    background: #ffffff;
    border-radius: 10px;
    padding: 10px 15px 10px 35px;
    background-image: url(/img/search.svg);
    background-position: 12px 12px;
    background-repeat: no-repeat;
    border: 1px solid #e7edf4;
    border-color: #E0E9F4 !important;
    box-shadow: 0px 1px 2px 0px #1018280D;
  }

  &::v-deep {
    .vs-dialog {
      min-width: 0 !important;
      height: 654px;
      border-radius: 16px !important;
      overflow: hidden;

      &__content {
        margin-bottom: 0 !important;
      }

      &__close {
        top: 24px !important;
        right: 24px !important;
        background-color: #F6F8FC !important;
        padding: 2px !important;

        @media screen and (max-width: 500px) {
          top: 16px !important;
          right: 20px !important;
        }

        i {
          width: 24px;
          height: 24px;

          &::before,
          &::after {
            top: 13px;
            right: 7px;
            background: #BFCEE0 !important;
            height: 1.5px !important;
          }
        }
      }
    }
  }
}

button {
  height: 40px;
  color: #FFF;
  border: none;
  border-radius: 8px;
  border-color: #14AD4D !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.gap-16 {
  gap: 16px;
}

.color-mute {
  color: #6C7E95
}

.flex-grow-1 {
  flex-grow: 1;
}
</style>