<template>
  <div v-if="data.testimonials.length && data.showTestimonials">
    <Testimonial
      :title="title"
      :theme="data.themeType"
      :images="data.testimonials"
      :templateType="data.templateType"
    />
    <!-- <div
      class="testimonial-container"
      :class="this.data.themeType == 'Classic' ? 'classic' : ''"
    >
      <div
        class="testimonial-title-bottom text-left"
        :class="this.data.themeType == 'Classic' ? 'classic' : ''"
      >
        {{ $t('common.Testimonials') }}
      </div>
      <div
        class="testimonial-wrapper"
        :class="this.data.themeType == 'Classic' ? 'classic' : ''"
      >
        <div class='testimonials' :class="this.data.themeType != 'Classic' && 'mt-16'">
          <div :key='testimonial._id' v-for='testimonial in data.testimonials' class="testimonials-list">
            <div class='flex justify-start items-start'>
              <div>
                <img style='width: 48px; height: 48px; object-fit: cover; border-radius: 50%;' :src='testimonial.avatar ? baseUrl+"/uploads/product-images/"+testimonial.avatar : require("@/assets/icons/default-profile.svg")'/>
              </div>
              <div class="ml-2">
                <div class='font-bold text-black'>{{testimonial.name}}</div>
                <div class='text-black' style='letter-spacing: 0.02em;font-size: 13px;'>{{testimonial.message}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="upload_wrapper w-100">
          <div
            style="
              padding-bottom: 20px;
              margin-bottom: 20px;
              width: 100%;
            "
            class="testimonial-carousel"
            :class="data.templateType == 'NoSidebar' ? 'nosidebar-testimonial-carousel' : ''"
          >
          <no-ssr>
            <Carousel
              :images="data.testimonials"
              :nuxt2="data.nuxt2"
              :type="data.themeType=='Tuktuk' ? 'testimonial--tuktuk':'testimonial'"
              :autoSlideSettings="data.autoSlideSettings"           
            ></Carousel>
          </no-ssr>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import moment from "moment";
import config from "./../../config.js";
// const Carousel = (() => import('@/components/Carousel'))
// import Carousel from "@/components/Carousel";
import Testimonial from "@/components/Form/components/testimonial";
import { getSizedImageUrl } from "@/helpers/imagekitHelper";

export default {
  props: ["data", "form", "embed"],
  data() {
    return {
      baseUrl: config.api.replace("/api", ""),
      customWidth: process.client ? window.innerWidth :"",
      getSizedImageUrl
    };
  },
  components:{
    // Carousel: Carousel,
    Testimonial,
  },
  filters: {
    year_format(val) {
      return moment(val).format("YYYY");
    },
  },
  computed: {
    title() {
      switch (this.data.themeType) {
        case 'Classic':
          return 'Testimoni';
        case 'Tuktuk':
          return 'Testimoni';
        default:
          return 'Testimonial';
      }
    }
  }
};
</script>

<style scoped>
.testimonial-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #6c7e95;
}

.testimonial-title.classic {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #203551;
}

.testimonial-title-bottom {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #203551;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.testimonials .text-black {
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #6c7e95;
}

.testimonials .text-black.font-bold {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #203551;
  margin-bottom: 5px;
}

.classic-copy-bottom {
  padding-bottom: 0;
}
.embed-testimonial-img {
  margin-right:20px;
}

.embed-testimonial-img img {
  max-width: 210px;
  height: 220px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 8px;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.nosidebar-testimonial-img-embed img{
  max-width: 112px;
  height: 115px;
}

@media (max-width: 575px) {
  .classic-copy-bottom {
    padding-bottom: 0;
  }
}
</style>