module.exports = {
    api: "https://app-staging.formulir.com/api/",
    isDev: false,
    baseUrl: 'https://staging.form.id',
    googleCaptchaKey: "8133ec97-b0eb-43fe-bbc4-710ea27745c5",
    imagekit:{
        thumbnail:"https://media.form.id/gcs/tr:n-ik_thumbnail",
        medium: "https://media.form.id/gcs/tr:n-ik_medium",
        full:"https://media.form.id/gcs/tr:n-ik_full/",      
    },
     shippingUrl:"https://research2.mengantar.com/api/"
};
